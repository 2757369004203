import React from 'react';

interface IProps {
  window: string[][];
  windowBroken: string[][];
  onWindowBrokenUpdated: (windowBroken: string[][]) => unknown;
  config: any;
}
export const BreakingWays = (props: IProps) => {
  const [windowBroken, setWindowBroken] = React.useState(props.windowBroken);
  const [options] = React.useState([...Object.keys(props.config.paytable),"shield"]);
  const [selectedLeft, setSelectedLeft] = React.useState<string>(options[0]);
  const [selectedRight, setSelectedRight] = React.useState<string>(options[0]);

  const add = () => {
    windowBroken.push([selectedLeft, selectedRight]);
    props.onWindowBrokenUpdated(windowBroken);
  }
  const remove = (index: number) => {
    props.onWindowBrokenUpdated(windowBroken.filter((it, i) => i !== index));
  }
  React.useEffect(() => { setWindowBroken(props.windowBroken); }, [props.windowBroken]);
  return <div>
    { windowBroken.map((it, i) => <div key={`wbken-${i}`}>{it[0]} {it[1]} <span onClick={() => remove(i)} style={{cursor: 'pointer'}}><i className={"fa-solid fa-circle-xmark"}></i></span></div>)}
    <div>
      <select value={selectedLeft} onChange={(e) => setSelectedLeft(e.currentTarget.value)}>
        {options.map((k) => <option key={`${k}-left`} value={k} label={k} />)}
      </select>
      <select value={selectedRight} onChange={(e) => setSelectedRight(e.currentTarget.value)}>
        {options.map((k) => <option key={`${k}-right`} value={k} label={k} />)}
      </select>
      <button onClick={() => add()}>Add</button>
    </div>
  </div>
}