export const range = (size: number, startAt: number = 0): Array<number> =>  Array.from(Array(size).keys()).map(i => i + startAt);

export const wrap = (n: number, reel: Array<unknown>) => n % reel.length;
export function randRange(min: number, max: number) { return Math.floor(Math.random() * (max - min + 1)) + min; }
export function isNumeric(str: any) {
  if (str == null) {
    return false;
  }
  const regx = /^[-+]?\d*\.?\d+(?:[eE][-+]?\d+)?$/;
  return regx.test(str);
}
export const getSymbolCountsFromWindow = (window: string[][]) => {
  const symbols: {[key: string]: number} = {};
  window.forEach((row) => row.forEach((symbol) => {
    !symbols[symbol] && (symbols[symbol] = 0);
    symbols[symbol] += 1;
  }));
  return symbols;
};

export const generateWindowFromReelStrips = (props: { reelIndexes: number[], reelStrips: string[][], reels: number, rows: number}) => {
  const {reelIndexes, reelStrips, reels, rows} = props;
  const w: string[][] = reelIndexes.map(() => []);
  range(reels).map((ri) => range(rows).forEach((ci) => {
    const reels = reelStrips[ri];
    const n = reelIndexes[ri] + ci;
    const c = wrap(n, reels);
    const b = c < 0 ? (reels.length + c) : c;
    w[ri][ci] = reels[b];
  }));
  return w;
}