import { isNumeric } from "../../../helpers";
import { ICoin } from "../interfaces";

export type TCoinWindow = Array<Array<ICoin | null>>;
export const getCoins = (config: any): Array<{ name: string; coin: ICoin}> => { 
  return config.coinFeature.coins.map((c: any) => {
    return {
      name: `${c}`,
      coin: {
        startWindowIndex: 0, 
        multiplier: isNumeric(c) ? c : 0, 
        totalWinInCredits: 0, 
        enabled: true, 
        coinType: isNumeric(c) ? 'price' : c, 
        history: [
          { type: isNumeric(c) ? 'price' : c, 
          multiplier: isNumeric(c) ? c : 0, 
          totalWinInCredits: 0,
        }],
      } as ICoin,
    }
  });
}

export function copyWindow<T>(window: T[][]): T[][] { return window.map((reel) => reel.map((col) => {
  if (col === null || typeof col === 'string' || isNumeric(col)) { return col; }
  return { ...col };
})) };

export const coinWindowsMatch = (window: string[][], coinSpinsWindow?: (ICoin | null)[][]) => {
  if (!window || !coinSpinsWindow) { return false; }
  if (window.length !== coinSpinsWindow.length) { return false; }
  for (let ri = 0; ri < window.length; ri++) {
    for(let ci = 0; ci < window[ri].length; ci++) {
      if (window[ri][ci] === 'coin' && coinSpinsWindow[ri][ci] === null) { return false; }
      if (window[ri][ci] !== 'coin' && coinSpinsWindow[ri][ci] !== null) { return false; }
    }
  }
  return true;
}