import React from 'react';
import './../ff001-starstruck-lines/ff001-starstruck.css';
import { IScenarioDB } from './interfaces';
import Spin from './spin/spin';
import request from 'superagent';
import { generateWindowFromReelStrips, getSymbolCountsFromWindow } from '../../helpers';

const Model = (props: { modelId: any; }) => {
  const [modelId] = React.useState(props.modelId);
  const [render, setRender] = React.useState(false);
  const [config, setConfig] = React.useState<any>(null);
  const [userId, setUserId] = React.useState("");
  const [scenarios, setScenarios] = React.useState<IScenarioDB[]>([]);
  const [selectedScenario, setSelectedScenario] = React.useState('');
  const [scenarioList, setScenarioList] = React.useState<({_id: string; scenarios: IScenarioDB[]})[]>([])
  const [allowAddFreespins, setAllowAddFreespins] = React.useState(false);
  const changeUserId = (txt: string) => {
    setUserId(txt);
    localStorage.setItem('userid', txt);
  } 
  const generateDummyScenario = (config: any) => {
    const dummyScenario: IScenarioDB = { 
      reelIndexes: config.reelsSetup.map(() => 0),
      otherData: { coinSpins: [], coinSpinIndex: 0, windowBroken: [] } 
    };
    return dummyScenario;
  }
  const getLabelForScenario = (scenario: IScenarioDB[]) => {
    let str = `sc. `;
    const s = scenario[0];
    const reels = config.reelsSetup.length;
    const rows = config.reelsSetup[0];
    const w = generateWindowFromReelStrips({
      reels,
      rows,
      reelIndexes: s.reelIndexes,
      reelStrips: config.baseSpinsReels, 
    });
    const c = getSymbolCountsFromWindow(w);
    (c['shield'] || 0) >= 3 && (str += `${c['shield']} shield, `);
    (c['scat'] || 0) >= 3 && (str += `${c['scat']} scat, `);
    (c['coin'] || 0) >= 3 && (str += `${c['coin']} coins, `);
    (c['wild'] || 0) > 0 && (str += `${c['wild']} wild, `);
    return str;
  }
  const analyzeWindow = (scenario: IScenarioDB[]) => {
    const s = scenario[0];
    const reels = config.reelsSetup.length;
    const rows = config.reelsSetup[0];
    const w = generateWindowFromReelStrips({
      reels,
      rows,
      reelIndexes: s.reelIndexes,
      reelStrips: config.baseSpinsReels, 
    });
    const c = getSymbolCountsFromWindow(w);
    setAllowAddFreespins(
         (c['scat'] ?? 0) >= 3
      )
  }
  const changeScenarioFromBelow = (scenario: IScenarioDB, index: number) => {
    scenarios[index] = scenario;
    setScenarios([...scenarios]);
  }
  const newScenario = () => {
    setSelectedScenario('');
  }
  const addNewFreespin = () => {
    scenarios.push({...generateDummyScenario(config)});
    setScenarios([...scenarios]);
  };
  const removeFreeSpin = () => {
    if (scenarios.length === 1) { return; }
    scenarios.pop();
    setScenarios([...scenarios]);
  };
  const save =  async () => {
    const payload = {
      scenarios,
      modelId,
      _id: selectedScenario === '' ? undefined : selectedScenario,
    }
    const res = (await request.post(`${process.env.REACT_APP_API_URL}/n/slots/scenario/upsert`).withCredentials().send(payload))?.body;
    if (!res.success) { return alert('Error saving scenario'); }
    console.log('Scenario saved');
  };
  const del =  async () => {
    if (!window.confirm('Confirm deletion')) { return; }
    const payload = {
      scenarios,
      _id: selectedScenario,
    }
    const res = (await request.post(`${process.env.REACT_APP_API_URL}/n/slots/scenario/delete`).withCredentials().send(payload))?.body;
    if (!res.success) { return alert('Error deleting scenario'); }
    alert('Scenario deleted');
    window.location.reload();
  };
  const play =  async () => {
    if (userId.trim() === '') { return alert('Please enter a user id'); }
    const payload: { userId: string; modelId: string; scenarios: IScenarioDB[]} = {
      scenarios,
      modelId,
      userId,
    }
    const res = (await request.post(`${process.env.REACT_APP_API_URL}/n/slots/scenario/play-user`).withCredentials().send(payload))?.body;
    if (!res.success) { return alert('Error saving scenario'); }
    console.log('Scenario saved');
  }; 
  React.useEffect(() => {
    if (!config) { return; }
    if (scenarios.length === 0) { setRender(true); return; }
    analyzeWindow(scenarios);
    setRender(true);
  }, [scenarios]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    const ss = scenarioList.find(it => it._id === selectedScenario)?.scenarios;
    if (selectedScenario === '' || !ss) { return; }
    const newS = JSON.parse(JSON.stringify(ss)) as IScenarioDB[];
    console.log(JSON.stringify(newS));
    setScenarios(newS);
  }, [selectedScenario]); // eslint-disable-line react-hooks/exhaustive-deps
  
  React.useEffect(() => {
    localStorage.getItem('userid') && setUserId(`${localStorage.getItem('userid')}`); 
    let req: request.SuperAgentRequest | undefined; 
    req = request.post(`${process.env.REACT_APP_API_URL}/n/slots/scenario/get-config`).withCredentials().send({ modelId });
    req.then(async (configRes) => { 
      const config = configRes.body.config;
      setConfig(config); 
      
      req = request.post(`${process.env.REACT_APP_API_URL}/n/slots/scenario/get`).withCredentials().send({ modelId });
      req.then(async (scenariosRes) => { 
        if (!scenariosRes.body.success) { return alert('Error loading scenarios'); }
        req = undefined;
        const scenarios = scenariosRes.body.scenarios;
        setScenarioList(scenarios);
        setScenarios(scenarios?.[0]?.scenarios ?? [{...generateDummyScenario(config)}] ); 
        setRender(true);
        console.log('scenarios', scenariosRes.body.scenarios);
      }).catch(e => console.error(e));
    }).catch(e => console.error(e)); 
    return () => {
      try { req?.abort(); } catch (e) { console.log(`Req aborted`); } // react 18 does this weird mount-unmount
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps 
  return !render ? <></> : (
    <div className="ff001-container">
      <h1>{modelId}</h1>
      <div className='scenario-presets'>
        <h3 style={{marginBottom: 5}}>Scenario presets</h3>
        <div className='ff001-scenario-select'>
          <select value={selectedScenario} onChange={e => setSelectedScenario(e.target.value)} >
            <option label={''} value={''} />
            {scenarioList.map((ps, i) => <option key={`ps-${i}`} value={`${ps._id}`} label={getLabelForScenario(ps.scenarios)} /> ) }
          </select>
        </div>
      </div>
      {scenarios.map((scenario, i) => (
        <Spin
          key={`${selectedScenario}-${i}`} 
          config={config}
          scenario={scenario}
          index={i}
          isLastSpin={i === scenarios.length - 1}
          onScenarioUpdated={(scenario, i) => changeScenarioFromBelow(scenario, i)}
        />))} 

      { allowAddFreespins && <div className='btn-freespins-group'> 
        <div className={'btn-freespins-action'} onClick={e => addNewFreespin()}>Add freespin</div>
        <div className={'btn-freespins-action'} onClick={e => removeFreeSpin()}>remove freespin</div>
      </div>}
      <div className='ff001-sep'>&nbsp;</div>
      <div className={`ff001-userid`}>
        <h3 style={{marginRight: 8}}>User Id</h3>
        <div>
          <input type={'text'} value={userId} onChange={(e) => changeUserId(e.currentTarget.value) } />
        </div>
      </div>
      <div style={{fontSize: 8}}>To find it: Go to the game, network tab, filter by "init", under Preview, user then id </div>
        
      <div className='model-buttons'>
        <div className={'btn-save'} onClick={e => newScenario()}>New</div>
        <div className={'btn-save'} onClick={e => save()}>Save</div>
        {selectedScenario !== '' && <div className={'btn-save'} onClick={e => del()}>Delete</div>}
        <div className={'btn-save btn-play'} onClick={e => play()}>Play</div>
      </div>

    </div>
  );
}
export default Model;
