import React from 'react';
import { useNavigate } from "react-router-dom";
import './home.css';

function Home() {
  const navigate = useNavigate();
  return (
    <div className='home-content'>
      <h3 onClick={() => navigate('/ff001-starstruck')}>ff001-starstruck (Jewels Odyssey)</h3>
      <h3 onClick={() => navigate('/ff001-starstruck-lines')}>ff001-starstruck-lines (fortune-of-the-great-plains)</h3>
      <h3 onClick={() => navigate('/ff002-carnival')}>ff002-carnival (Carnival of Mystique)</h3>
      <h3 onClick={() => navigate('/ff003-viking')}>ff003-viking (Odins )</h3>
      <h3 onClick={() => navigate('/ff004-china')}>ff004-china (China)</h3>
      <h3 onClick={() => navigate('/ff005-mexico')}>ff005-mexico (Mexico)</h3>
      <h3 onClick={() => navigate('/ff006-retro-future')}>ff006-retro-future (Retro Future)</h3>
      <h3 onClick={() => navigate('/ff007-greek')}>ff007-greek (Greek)</h3>
      <h3 onClick={() => navigate('/ff008-egypt')}>ff008-egypt (Egypt)</h3>
      <h3 onClick={() => navigate('/ff009-mad-scientist')}>ff009-mad-scientist (Quantum Quikks)</h3>
      <h3 onClick={() => navigate('/ff010-breaking-ways')}>ff010-breaking-ways (Breaking Ways)</h3>
      <h3 onClick={() => navigate('/ff011-breaking-buffalo')}>ff011-breaking-buffalo (Breaking Ways)</h3>
    </div>
  );
}

export default Home;
