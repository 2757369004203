import React from 'react';
import './app.css';
import FF001StarstruckLines from './components/ff001-starstruck-lines';
import FF002Carnival from './components/ff002-carnival';
import FF008Egypt from './components/ff008-egypt';
import FF010BreakingWays from './components/ff010-breaking-ways';
import Login from './components/login/login';
import Home from './components/home/home';
import * as request from 'superagent'; 
import {
  createHashRouter,
  RouterProvider,
} from "react-router-dom";

function App() {
  const [checking, setChecking] = React.useState(true);
  const [loggedIn, setLoggedIn] = React.useState(false);

  const router = createHashRouter([
    { path: '/', element: <Home /> },
    { path: 'ff001-starstruck', element: <FF001StarstruckLines modelId={'ff001-starstruck'} /> },
    { path: 'ff001-starstruck-lines', element: <FF001StarstruckLines modelId={`ff001-starstruck-lines`} /> },
    { path: 'ff002-carnival', element: <FF002Carnival modelId={`ff002-carnival`} /> },
    { path: 'ff003-viking', element: <FF002Carnival modelId={`ff003-viking`} /> },
    { path: 'ff004-china', element: <FF002Carnival modelId={`ff004-china`} /> },
    { path: 'ff005-mexico', element: <FF002Carnival modelId={`ff005-mexico`} /> },
    { path: 'ff006-retro-future', element: <FF002Carnival modelId={`ff006-retro-future`} /> },
    { path: 'ff007-greek', element: <FF002Carnival modelId={`ff007-greek`} /> },
    { path: 'ff008-egypt', element: <FF008Egypt modelId={`ff008-egypt`} /> },
    { path: 'ff009-mad-scientist', element: <FF002Carnival modelId={`ff009-mad-scientist`} /> },
    { path: 'ff010-breaking-ways', element: <FF010BreakingWays modelId={`ff010-breaking-ways`} /> },
    { path: 'ff011-breaking-buffalo', element: <FF010BreakingWays modelId={`ff011-breaking-buffalo`} /> },
  ]);
  const init = async () => {
    const res = (await request.post(`${process.env.REACT_APP_API_URL}/n/slots/scenario/login`).withCredentials().send({cookiecheck: true}))?.body;
    setChecking(false);
    if (!res.success) { return; }
    setLoggedIn(!!res.success);
  }
  React.useEffect(() => {
    init();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  return checking ? <>Loading</> : (
    <div className="app">
      {!loggedIn 
        ? <Login onLoggedIn={() => setLoggedIn(true)} />
        : <> 
          <RouterProvider router={router} />
        </> }
    </div>
  );
}

export default App;
