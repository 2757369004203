import React from 'react';
import './../../ff001-starstruck-lines/ff001-starstruck.css';
import { isNumeric, wrap, range, getSymbolCountsFromWindow, generateWindowFromReelStrips } from './../../../helpers';
import { Reel } from './../../reel/reel';
import { ICoin } from './../../ff001-starstruck-lines/interfaces';
import { IScenarioDB } from './../interfaces';
import * as coinHelpers from './../../ff001-starstruck-lines/coin-spins/helpers';
import CoinSpins from './../../ff001-starstruck-lines/coin-spins/coin-spins';
import {BreakingWays} from './../breaking-ways/breaking-ways';

function Spin(props: { config: any; scenario: IScenarioDB, index: number; isLastSpin: boolean; onScenarioUpdated: (scenario: IScenarioDB, i: number) => unknown }) {
  const [config] = React.useState(props.config);
  const [window, setWindow] = React.useState<string[][]>([]);
  const updateScenario = (params: Partial<IScenarioDB>) => {
    const newScenario = {...props.scenario, ...params};
    props.onScenarioUpdated(newScenario, props.index);
  }
  const setReelIndex = (params: {reelIndex: number, dir?: 1 | -1, forcedIndex?: number }) => {
    const { reelIndex, dir, forcedIndex } = params;
    const reelIndexes = props.scenario.reelIndexes;
    const reels = config.baseSpinsReels[reelIndex];
    const n = (typeof forcedIndex === 'number') ? forcedIndex : reelIndexes[reelIndex] + (1 * dir!);
    const c = wrap(n, reels);
    const w = c < 0 ? (reels.length + c) : c;
    updateScenario({reelIndexes: reelIndexes.map((x, i) => i === reelIndex ? w : x) });
  }
  const onCoinSpinsWindowsUpdated = (coinSpins: (ICoin | null)[][][]) => {
    updateScenario({otherData: {...props.scenario!.otherData, coinSpins, coinSpinIndex: 0 }});
  }
  const onBrokenWindowUpdated = (windowBroken: string[][]) => {
    updateScenario({otherData: {...props.scenario!.otherData, windowBroken }});
  }
  React.useEffect(() => {
    const t = setTimeout(() => {
      const w = getSymbolCountsFromWindow(window);
      const coin = w['coin'] ?? 0;
      const triggerCount = config.coinFeature.coinTrigger ?? 100000; if (triggerCount===100000) { throw new Error('CoinTrigger isnt found the config')}
      if (coin >= triggerCount ) {
        if (coinHelpers.coinWindowsMatch(window, props.scenario?.otherData?.coinSpins?.[0])) { return; }
        console.log(`creating new coin spins game coins: ${coin} spins: ${config.coinFeature.coinTriggerSpinCount}`);
        const coins = coinHelpers.getCoins(props.config);
        const firstWindow = window.map((reel, ri) => reel.map((col, ci) => col === 'coin' ? {...coins[0].coin, startWindowIndex: 0} : null));
        const cs = range(4).map((i) => {
          if (i === 0) { return firstWindow };
          return coinHelpers.copyWindow(firstWindow);
        });
        onCoinSpinsWindowsUpdated(cs);
      } else {
        onCoinSpinsWindowsUpdated([]);
      }
    }, 250);
    return () => { clearTimeout(t); }
  }, [window]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    const reels = config.reelsSetup.length;
    const rows = config.reelsSetup[0];
    const w = generateWindowFromReelStrips({
      reels,
      rows,
      reelIndexes: props.scenario.reelIndexes,
      reelStrips: props.index > 0 ? config.freeSpinsReels : config.baseSpinsReels,
    });
    setWindow(w);
  }, [props.scenario.reelIndexes]); // eslint-disable-line react-hooks/exhaustive-deps
  
  return <div className={`spin ${props.index > 0 ? 'spin-freespins' : ''}`}>
    <div className='spin-index-num'>{props.index}</div>
    <div className={'reelContainer'}>
      {props.scenario.reelIndexes.map((ri, i) => <Reel 
        reelIndex={ri} 
        setReelIndex={ (props: {reelIndex: number, dir?: 1 | -1, forcedIndex?: number }) => setReelIndex(props) } 
        reelStrips={(props.index > 0 ? config.freeSpinsReels : config.baseSpinsReels)[i]} 
        index={i} 
        rows={config.reelsSetup[i]} 
        key={`reel-${props.index}-${i}`}/>)}
    </div>
    <BreakingWays window={window} windowBroken={props.scenario?.otherData?.windowBroken || []} onWindowBrokenUpdated={onBrokenWindowUpdated} config={props.config} />
    <CoinSpins window={window} coinSpinsWindow={props.scenario?.otherData?.coinSpins || []} onCoinSpinsWindowsUpdated={onCoinSpinsWindowsUpdated} config={props.config} />
  
  </div>
}
export default Spin;