import React from 'react';
import { isNumeric, range, wrap } from './../../helpers';
import './reel.css';

export const Reel = (props: { reelStrips: string[]; index: number; rows: number; reelIndex: number; setReelIndex: (props: {reelIndex: number, dir?: 1 | -1, forcedIndex?: number }) => unknown }) => {
  const [reel, setReel] = React.useState<Array<{ name: string; reelIndex: number}>>([]);
  const [rows, setRows] = React.useState<number[]>(range(props.rows));
  const [rollingOver, setRollingOver] = React.useState<boolean>(false);
  const [reelIndexTxt, setReelIndexTxt] = React.useState<number>(0);
  const [slideAction, setSlideAction] = React.useState<-1 | 0 | 1>(0);
  const setReelIndexRef = React.useRef(props.setReelIndex);
  const reelDiv = React.useRef<HTMLDivElement>(null); 
  const slide = () => {
    setReelIndexTxt(props.reelIndex);
    setReel(rows.map((i) => {
      const n = props.reelIndex + i;
      const c = wrap(n, props.reelStrips);
      const b = c < 0 ? (props.reelStrips.length + c) : c;
      const wrappedIndex = b < 0 ? (props.reelStrips.length + b) : b;
      return ({name: props.reelStrips[wrappedIndex], reelIndex: wrappedIndex});
    }));
  }
  const changeReelIndexFromText = (txt: string) => {
    setReelIndexTxt(parseInt(txt));
  }

  // a small delay to update the reel index from the text input
  React.useEffect(() => {
    const timeout = setTimeout(() => {
      if (!isNumeric(reelIndexTxt)) { return; }
      setReelIndexRef.current({ reelIndex: props.index, forcedIndex: Number(reelIndexTxt) });
    }, 250);
    return () => { clearTimeout(timeout); }
  }, [reelIndexTxt]); // eslint-disable-line react-hooks/exhaustive-deps

  // press slider
  React.useEffect(() => {
    let interval: any;
    if (slideAction === -1 || slideAction === 1) {
      setReelIndexRef.current({ reelIndex: props.index, dir: slideAction });
      const callback = () => {
        setSlideAction(0);
        window.removeEventListener('mouseup', callback);
      };
      window.addEventListener('mouseup', callback);
      interval = setInterval(() => { setReelIndexRef.current({ reelIndex: props.index, dir: slideAction }); }, 100);
    }
    return () => { clearInterval(interval) };
  }, [slideAction]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => { slide(); }, [props.reelIndex]); // eslint-disable-line react-hooks/exhaustive-deps
  React.useEffect(() => { setRows(range(props.rows)); }, [props.rows]); // eslint-disable-line react-hooks/exhaustive-deps
  React.useEffect(() => { setReelIndexRef.current = props.setReelIndex; }, [props.setReelIndex]); // eslint-disable-line react-hooks/exhaustive-deps
  
  // press slider
  React.useEffect(() => {
    const doWheel = (e: WheelEvent) => { e.preventDefault(); setReelIndexRef.current({ reelIndex: props.index, dir: Math.sign(e.deltaY) as any }); };
    if (!rollingOver) { reelDiv?.current?.removeEventListener("wheel", doWheel); return; }
    reelDiv?.current?.addEventListener("wheel", doWheel);
    // return () => { reelDiv?.current?.removeEventListener?.("wheel", doWheel); };
  }, [rollingOver]); // eslint-disable-line react-hooks/exhaustive-deps
 
  return <div ref={reelDiv} className='reel' onMouseEnter={() => setRollingOver(true)} onMouseLeave={() => setRollingOver(false)}>
    <div className='reel-input'>
      <input type={'number'} value={reelIndexTxt} onChange={(e) => changeReelIndexFromText(e.currentTarget.value)} />
    </div>
    <div className='reel-arrows' onMouseDown={()=> setSlideAction(-1)}>
      <i className="fa-solid fa-caret-up"></i>
    </div>
    {reel.map((it, i) => <div key={`reel-${props.index}-${i}-${Math.random()}`} className='reel-item'>{it.name}</div>)}
    <div className='reel-arrows' onMouseDown={() => setSlideAction(1)}>
      <i className="fa-solid fa-caret-down"></i>
    </div>
  </div>
}