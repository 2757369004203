import React from 'react';
import './style.css';
import { ICoin } from '../interfaces';
import { getCoins, TCoinWindow, copyWindow } from './helpers';

interface IProps {
  window: string[][];
  coinSpinsWindow: TCoinWindow[];
  onCoinSpinsWindowsUpdated: (coinSpinsWindow: TCoinWindow[]) => void;
  config: any;
}
const Model = (props: IProps) => {
  const [containerId] = React.useState(`${Math.random()}`);
  const [expanded, setExpanded] = React.useState<boolean>(true);
  
  const addStep = () => {
    const ncw = props.coinSpinsWindow.map((window, i) => copyWindow(window));
    ncw.push(copyWindow(ncw[ncw.length-1]));
    props.onCoinSpinsWindowsUpdated(ncw);
  }
  const removeStep = () => {
    const ncw = props.coinSpinsWindow.map((window, i) => copyWindow(window));
    if (ncw.length <= 1) { return };
    ncw.pop();
    props.onCoinSpinsWindowsUpdated(ncw);
  }
  const propagateChanges = (params: { index: number, reelIndex: number, rowIndex: number, coin: ICoin | null }) => {
    const newCoinsSpinsWindow = props.coinSpinsWindow.map((window, i) => {
      if (i < params.index) { return window; }
      const nw = copyWindow(window);
      nw[params.reelIndex][params.rowIndex] = params.coin === null ? null : {...params.coin, startWindowIndex: params.index};
      return nw;
    });
    props.onCoinSpinsWindowsUpdated(newCoinsSpinsWindow);
  }
  return props.coinSpinsWindow.length === 0 ? <></> : <>
    <h3 onClick={() => setExpanded(!expanded)} style={{cursor: 'pointer'}}>
      <span style={{marginRight: 8}}><i className={"fa-solid fa-coins"}></i></span>
      Coin Spins <i className={`fa-solid fa-caret-${expanded ? 'down' : 'right'}`}></i>
    </h3>
    { expanded && <>
      {props.coinSpinsWindow.map((window, i) => (
        <div key={`${containerId}-${i}`} className='coin-spins'>
          <Window window={window} index={i} isLast={i === props.coinSpinsWindow.length-1} propagateChanges={propagateChanges} config={props.config} /> 
          <div className={'sep'}></div>
        </div>))}
      <div className='coin-spins-btns'>
        <div className='btn-cs-action' onClick={() => addStep()} style={{marginRight: 8}}>Add Step</div>
        <div className='btn-cs-action' onClick={() => removeStep()} style={{marginLeft: 8}}>Remove Step</div>
      </div>
    </>}
  </>;
}
export default Model;

interface IWindowProps {
  index: number;
  window: TCoinWindow;
  isLast: boolean;
  config: any;
  propagateChanges: (props: { index: number, reelIndex: number, rowIndex: number, coin: ICoin | null }) => void;
}
const Window = (props: IWindowProps) => {
  return <>
    <div className="coin-spins-window">
      { props.window.map((reel, ri) => (
        <div className="coin-spins-reel" key={`coin-spins-reel-${ri}`}>
          {reel.map((col, ci) => <Item key={`cs-item-${props.index}-${ri}-${ci}`} config={props.config} index={props.index} reelIndex={ri} rowIndex={ci} coin={col} propagateChanges={props.propagateChanges} />)}
        </div>))}
    </div>
  </>;
}
interface IItemProps { 
  config: any;
  coin: ICoin | null; 
  index: number;
  reelIndex: number; 
  rowIndex: number;
  propagateChanges: (props: { index: number, reelIndex: number, rowIndex: number, coin: ICoin | null }) => void;
}
const Item = (props: IItemProps) => {
  const [selectedCoin, setSelectedCoin] = React.useState("");
  const findCoinBasedOnSelectedCoin = (coin: string) => (getCoins(props.config).find((it) => `${it.name}` === `${coin}`))?.coin || null;
  const changeCoin = (c: string) => {
    const coin = findCoinBasedOnSelectedCoin(c);
    props.propagateChanges({ index: props.index, reelIndex: props.reelIndex, rowIndex: props.rowIndex, coin });
  }
  const isDisabled = () => {
    const coin = props.coin;
    if (props.index === 0) { return true; }
    if (coin === null) { return false; }
    if (coin.startWindowIndex === props.index) { return false; }
    return true;
  }
  React.useEffect(() => {
    const pc = props.coin;
    const co = getCoins(props.config).find((it) => {
      if (pc?.coinType === 'price' && `${pc.multiplier}` === `${it.coin.multiplier}`) { return true; }
      if (!!pc && pc.coinType !== 'price' && pc.coinType === it.coin.coinType) { return true; } //jackpot
      return false;
    });
    setSelectedCoin(co?.name || "");
  }, [props.coin]); // eslint-disable-line react-hooks/exhaustive-deps
  return <div className="coin-spins-item">
    <select value={selectedCoin} disabled={isDisabled()} onChange={(e) => changeCoin(e.currentTarget.value)}>
      <option label={'-'} value={''} />
      { getCoins(props.config).map((coin) => (<option key={`cs-select-opt-${props.index}-${props.reelIndex}-${props.rowIndex}-${coin.name}`} value={`${coin.name}`} label={`${coin.name}`} />)) }
    </select>
  </div>
}