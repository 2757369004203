import React from 'react';
import './../ff001-starstruck.css';
import { isNumeric, wrap, range, getSymbolCountsFromWindow, generateWindowFromReelStrips } from './../../../helpers';
import { Reel } from './../../reel/reel';
import { ICoin, IScenarioDB } from './../interfaces';
import * as coinHelpers from './../coin-spins/helpers';
import CoinSpins from './../coin-spins/coin-spins';

function Spin(props: { config: any; scenario: IScenarioDB, index: number; isLastSpin: boolean; onScenarioUpdated: (scenario: IScenarioDB, i: number) => unknown }) {
  const [config] = React.useState(props.config);
  const [window, setWindow] = React.useState<string[][]>([]);
  const [allowWildMultiplier, setAllowWildMultiplier] = React.useState(false);
  const updateScenario = (params: Partial<IScenarioDB>) => {
    const newScenario = {...props.scenario, ...params};
    props.onScenarioUpdated(newScenario, props.index);
  }
  const setReelIndex = (params: {reelIndex: number, dir?: 1 | -1, forcedIndex?: number }) => {
    const { reelIndex, dir, forcedIndex } = params;
    const reelIndexes = props.scenario.reelIndexes;
    const reels = config.baseSpinsReels[reelIndex];
    const n = (typeof forcedIndex === 'number') ? forcedIndex : reelIndexes[reelIndex] + (1 * dir!);
    const c = wrap(n, reels);
    const w = c < 0 ? (reels.length + c) : c;
    updateScenario({reelIndexes: reelIndexes.map((x, i) => i === reelIndex ? w : x) });
  }
  const changeWildMultiplier = (txt: string) => {
    const n = parseInt(txt);
    if (!isNumeric(txt)) { return; }
    updateScenario({ wildMultiplier: n });
  }
  const changePowerUp = (name: string) => {
    updateScenario({ powerUp: name === '' ? undefined: { name: name as any } });
  }
  const onCoinSpinsWindowsUpdated = (coinSpins: (ICoin | null)[][][]) => {
    updateScenario({otherData: { coinSpins, coinSpinIndex: 0}});
  }
  React.useEffect(() => {
    const t = setTimeout(() => {
      const w = getSymbolCountsFromWindow(window);
      const coin = w['coin'] ?? 0;
      const triggerCount = config.coinFeature.coinTrigger ?? 100000; if (triggerCount===100000) { throw new Error('CoinTrigger isnt found the config')}
      if (coin >= triggerCount ) {
        if (coinHelpers.coinWindowsMatch(window, props.scenario?.otherData?.coinSpins?.[0])) { return; }
        console.log(`creating new coin spins game coins: ${coin} spins: ${config.coinFeature.coinTriggerSpinCount}`);
        const coins = coinHelpers.getCoins(props.config);
        const firstWindow = window.map((reel, ri) => reel.map((col, ci) => col === 'coin' ? {...coins[0].coin, startWindowIndex: 0} : null));
        const cs = range(config.coinFeature.coinTriggerSpinCount + 1).map((i) => { // 3 spins
          if (i === 0) { return firstWindow };
          return coinHelpers.copyWindow(firstWindow);
        });
        onCoinSpinsWindowsUpdated(cs);
      } else {
        onCoinSpinsWindowsUpdated([]);
      }
      
      setAllowWildMultiplier((w['wild'] || 0 ) > 0);
    }, 250);
    return () => { clearTimeout(t); }
  }, [window]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    const reels = config.reelsSetup.length;
    const rows = config.reelsSetup[0];
    const w = generateWindowFromReelStrips({
      reels,
      rows,
      reelIndexes: props.scenario.reelIndexes,
      reelStrips: props.index > 0 ? config.freeSpinsReels : config.baseSpinsReels,
    });
    setWindow(w);
  }, [props.scenario.reelIndexes]); // eslint-disable-line react-hooks/exhaustive-deps
  
  return <div className={`spin ${props.index > 0 ? 'spin-freespins' : ''}`}>
    <div className='spin-index-num'>{props.index}</div>
    <div className={'reelContainer'}>
      {props.scenario.reelIndexes.map((ri, i) => <Reel 
        reelIndex={ri} 
        setReelIndex={ (props: {reelIndex: number, dir?: 1 | -1, forcedIndex?: number }) => setReelIndex(props) } 
        reelStrips={(props.index > 0 ? config.freeSpinsReels : config.baseSpinsReels)[i]} 
        index={i} 
        rows={4} 
        key={`reel-${props.index}-${i}`}/>)}
    </div>
    {allowWildMultiplier && <>
      <div className='ff001-wild-multiplier'>
        <h3 style={{marginRight: 8}}>
          <span style={{marginRight: 8}}><i className="fa-solid fa-wand-magic-sparkles"></i></span>
          Wild Multiplier
        </h3>
        <div>
          <select value={props.scenario.wildMultiplier ?? `1`} onChange={e => changeWildMultiplier(e.currentTarget.value)}>
            { props.config.wildMultipliers[props.index === 0 ? 'base' : 'freespins'].values.map((i: number) => <option key={`wm-${props.index}-${i}`} value={`${i}`} label={`${i}`} />) }
          </select>
        </div>
      </div>
    </>}
      
    { props.index === 0 && <>
      <div className='ff001-powerup'>
        <h3 style={{marginRight: 8}}>
          <span style={{marginRight: 8}}><i className={"fa-solid fa-star"}></i></span>
          Use a Power Up
        </h3>
        <select value={props.scenario?.powerUp?.name ?? ''} onChange={e => changePowerUp(e.target.value)} >
          <option label={''} value={''} />
          <option value={'token000'} label={'1 Scat randomly placed'} />
          <option value={'token001'} label={'3 Scat randomly placed'} />
          <option value={'token100'} label={'all Wild on reel 1'} />
          <option value={'token101'} label={'all Wild on reel 2'} />
          <option value={'token102'} label={'all Wild on reel 3'} />
          <option value={'token103'} label={'all Wild on reel 4'} />
          <option value={'token104'} label={'any Wilds multipliers as x2'} />
          <option value={'token105'} label={'any Wilds multipliers as x3'} />
          <option value={'token150'} label={'all F1 on reel 2'} />
          <option value={'token151'} label={'all F2 on reel 3'} />
          <option value={'token200'} label={'1 Coin randomly placed'} />
          <option value={'token201'} label={'2 Coin randomly placed'} />
          <option value={'token202'} label={'3 Coin randomly placed'} />
          <option value={'token203'} label={'6 Coins randomly placed'} />
          <option value={'token204'} label={'1 Coin mini'} />
          <option value={'token205'} label={'1 Coin minor'} />
        </select>
      </div>
    </>}

    <CoinSpins window={window} coinSpinsWindow={props.scenario?.otherData?.coinSpins || []} onCoinSpinsWindowsUpdated={onCoinSpinsWindowsUpdated} config={props.config} />
  
  </div>
}
export default Spin;